import React, { useState, useEffect } from "react";
import "./style.scss";
import { reduxForm, Field, change as onChangeForm } from "redux-form";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

import announcementValidator from "./announcementValidator";
const { Form } = require(`../../../../components/atoms/form`);
const {
  defaultConfig: { LOCATION },
} = require(`../../../../config/default`);
const { Input } = require(`../../../../components/atoms/input`);
const { STRINGS } = require(`../../../../shared/constants/${LOCATION}/strings`);
const {
  addAnnouncement,
  editAnnouncement,
} = require(`../../../../redux/actions`);
const { InputSubmit } = require(`../../../../components/atoms/input-submit`);
const { Select } = require(`../../../../components/atoms/select`);
const { onSubmitFail } = require(`../../../../helpers`);
const IbarScreen = (props) => {
  const [fields, setFields] = useState({
    announcement: "",
    redirect: "",
    answer: "",
    announcementDuration: "",
  });
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({
    value: "annoucement",
    label: "Annoucement",
  });
  const [announcementOptions, setAnnouncementOptions] = useState([
    {
      value: "annoucement",
      label: "Annoucement",
    },
  ]);
  const onsubmit = (e) => {
    e.preventDefault();
    if (
      fields.announcement != "" &&
      fields.redirect != "" &&
      fields.answer != "" &&
      fields.announcementDuration != ""
    ) {
      let postdata = {
        announcement: fields.announcement,
        redirect: fields.redirect,
        announcementDuration: fields.announcementDuration,
        type: 1,
        watchpartyId: props.watchPartyId,
      };
      if (
        props &&
        props.dialogFields &&
        props.dialogFields.edit &&
        props.dialogFields.edit.hasOwnProperty("_id")
      ) {
        props.editAnnouncement(
          { ...postdata, id: props.dialogFields.edit._id },
          (success) => {
            props.onConfirmation(success);
          },
          (failure) => {
            props.onConfirmation(failure);
          }
        );
      } else {
        props.addAnnouncement(
          postdata,
          (success) => {
            props.onConfirmation(success);
          },
          (failure) => {
            props.onConfirmation(failure);
          }
        );
      }
    }
  };
  const onChangeField = (type, value) => {
    setFields({ ...fields, [type]: value });
  };

  useEffect(() => {
    console.log(props);
    if (
      props &&
      props.dialogFields &&
      props.dialogFields.edit &&
      props.dialogFields.edit.hasOwnProperty("_id")
    ) {
      setFields({
        announcement: props.dialogFields.edit.content.text,
        redirect: props.dialogFields.edit.redirect,
        announcementDuration: props.dialogFields.edit.content.duration,
  
      });
    }
  }, [props && props.dialogFields]);
  return (
    <div className="container text-left">
      <Form onSubmit={onsubmit} className="text-left">
      
        <Field
          name={"announcement"}
          component={Input}
          label={"Card Content"}
          type={"text"}
          onChange={(event) =>
            onChangeField("announcement", event.target.value)
          }
          config={{
            value: fields.announcement ? fields.announcement : null,
          }}
          maxLength={120}
        />
        
        <Field
          name={"redirect"}
          component={Input}
           label={"Redirect"}
          type={"text"}
          onChange={(event) => onChangeField("redirect", event.target.value)}
          config={{
            value: fields.redirect ? fields.redirect : null,
          }}
          maxLength={120}
        />
        <div class="floating-label">
        <Field
          name={"answer"}
          component={Select}
           label={"Announcement"}
          options={announcementOptions}
          value={selectedAnnouncement}
          onChange={(value) => {
            onChangeField("answer", value.value);
            setSelectedAnnouncement(value.label);
          }}
          config={{
            value: selectedAnnouncement ? selectedAnnouncement : null,
          }}
        />
        </div>
        <Field
          name={"announcementDuration"}
          component={Input}
          label={"Duration(secs) multiples of 10"}
          type={"number"}
          onChange={(event) =>
            onChangeField("announcementDuration", event.target.value)
          }
          config={{
            value: fields.announcementDuration
              ? fields.announcementDuration
              : null,
              step: 10, min:0
          }}
        />
        <div className="d-flex justify-content-end">
          <InputSubmit buttonLabel={"Save"} color="primary" />
          <Button
            onClick={props.onRejection}
            className={"button-outline"}
            color="primary"
          >
            {"Cancel"}
          </Button>
        </div>
      </Form>
    </div>
  );
};
const reduxFormFunction = reduxForm({
  form: "IbarScreen",
  fields: ["email", "password"],
  onSubmitFail,
  validate: announcementValidator,
  enableReinitialize: true,
})(IbarScreen);

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAnnouncement: (data, success, failure) =>
      dispatch(addAnnouncement(data, success, failure)),
    editAnnouncement: (data, success, failure) =>
      dispatch(editAnnouncement(data, success, failure)),
  };
};

export const IbarForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxFormFunction);
